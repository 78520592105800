<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import moment from 'moment';
import {countryService} from "@/services/country.service";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Les pays",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      editing: false,
      pharmacies: [],
      countries: [
        {
          id: 1,
          code: 'tg',
          name: 'Togo'
        },
        {
          id: 2,
          code: 'gh',
          name: 'Ghana'
        }
      ],
      country: {},
      title: "Pays",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Pays",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "begin",
      sortDesc: false,
      fields: [
        { key: "code", label: "Code", sortable: true },
        { key: "name", label: "Nom", sortable: true },
        { key: "indicatif", label: "Indicatif", sortable: true },
        { key: "actions", thStyle: { width: "20%" } },
      ],
      submitted: false
    };
  },
  validations: {
    onCall: {
      code: { required },
      name: { required },
      indicatif: { required },
    }
  },
  computed: {
    rows() {
      return this.countries.length;
    }
  },
  mounted() {
    this.getCountries();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const editCountry = {
          name: this.country.name,
          code: this.country.code,
          indicatif: this.onCall.indicatif,
        }
        let result = this.country.id ? await countryService.update(this.country.id, editCountry) : await countryService.create(editCountry);
        if (result >= 400) {
          Swal.fire("Erreur", "Une erreur s'est produite !", "warning");
        } else {
          Swal.fire(this.onCall.id ? 'Modification' : 'Enregistrement', "Opération reussie !", "success")
          await this.getCountries();
          this.clearForm();
        }
      }
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    clearForm() {
      this.submitted = false;
      this.editing = false;
      this.country = {};
    },
    async getCountries() {
      this.countries = await countryService.get();
    },
    remove(id) {
      Swal.fire({
        title: "Suppression",
        text: `Vous êtes sûr de supprimer ${this.country.name} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non"
      }).then(async result => {
        if (result.value) {
          let result = await countryService.remove(id);
          if (result.status === 200) await Swal.fire("Suppression", "Pays supprimée avec succès !", "success");
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items">
      <button v-if="!editing" type="button" class="btn btn-success" @click="editing = true">Nouvelle garde</button>
    </PageHeader>

    <div v-if="editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouveau pays</h4>
            <p class="card-title-desc">Définir une nouvelle période de garde via le formulaire</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label" for="name">Nom</label>
                    <input id="name" v-model="country.name" type="text" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.country.name.$error }"/>
                    <div v-if="submitted && $v.country.name.$error" class="invalid-feedback">
                      <span v-if="!$v.country.name.required">Le nom du pays est obligatoire.</span>
                    </div>
                  </div>
                </div>

              </div>
              <button class="btn btn-success me-3" type="submit">Enregistrer</button>
              <button class="btn btn-secondary" type="reset" @click.prevent="clearForm">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des pays</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="countries"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template #cell(begin)="row">
                  {{ getFormattedDate(row.value) }}
                </template>

                <template #cell(end)="row">
                  {{ getFormattedDate(row.value) }}
                </template>

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-light">
                    Voir
                  </b-button>
                  <b-button size="sm" class="bg-warning mx-2" @click="country = row.item; editing = true">
                    Modifier
                  </b-button>
                  <b-button size="sm" class="bg-danger" @click="remove(row.item.id)">
                    Supprimer
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.multiselect__tag {
  background-color: #ececf1 !important;
  color: #000;
}
</style>

