import errorHandler from "@/services/handler/erroHandler";
import axiosInstance from "@/services/manager/manager.service";


export const countryService = {
    get,
    create,
    update,
    remove
};

async function get(){
    try {
        let rets = await axiosInstance.get(`/countries`);
        if (rets) {
            return rets.data;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

async function create(country) {
    try {
        let ret = await axiosInstance.post(`/countries`, country);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function update(id, data) {
    try {
        let ret = await axiosInstance.put(`/countries/${id}`, data);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function remove(id) {
    try {
        return await axiosInstance.delete(`/countries/${id}`);
    } catch (error) {
        return errorHandler(error);
    }
}



